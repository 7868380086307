import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const WarshipInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about Warship</h2>
      <p>
        There’s something in the sea ahead Captain, and it’s not fish! The radar
        has detected a fleet of enemy warships. It’s a war! Let the attack
        begin!
      </p>
      <p>
        Battleship (sometimes called Battleships, Sea Battle or Warship) is a
        classic game of skilful prediction and masterful strategy. This
        childhood favourite game could be played simply with a pen-and-paper, or
        could be played as a board game between 2 people. Place your ships on a
        grid, and strategically attack your opponents each turn to expose their
        location. Battleship is a fun memory - ships, sea and skills for sure
        shot entertainment!
      </p>
      <p>
        What if we told you that you could play Battleship online — on your
        android phone, with real people in real time? And what if you could win
        real money, just sinking ships? Download Mega and play Warship, a fast
        paced, multiplayer game of Battleship. Sink enemy ships with skills,
        strategy and speed, and win real money!
      </p>
    </>
  )

  const hidden = (
    <>
      <h3>How to Play</h3>
      <ul>
        <li>
          <p>
            Warship is played between 2-4 players in real time. Each player
            begins with 10 points and the aim is to ‘race to zero’ — attack
            enemy ships and reduce your score to 0 the fastest.
          </p>
        </li>
        <li>
          <p>
            Each player has 5 different ships at the start. Arrange them
            vertically or horizontally on the grid before the timer runs out.
            Tap on the ships to rotate and change orientation. No overlaps
            allowed. Click ‘Auto Place’ to randomly arrange them on the grid.
          </p>
        </li>
        <li>
          <p>
            Players take turns to determine the location of ships. Tap on any
            square in the grid to attack at that location. Each attack results
            in either a hit or a miss.
          </p>
        </li>
        <li>
          <p>
            In each turn, a player’s grid is attacked by all other players. The
            status of each attack - whether hit or miss - is revealed at the end
            of the turn.
          </p>
        </li>
        <li>
          <p>
            Each successful hit on a new opponent ship reduces your score by 2
            points. Subsequent hits on the remaining parts of the same ship
            reduces the score by only 1 point.
          </p>
        </li>
        <li>
          <p>
            If a player’s all ships get sunk, then the player loses the round.
            The player who makes enough successful attacks to reach 0 first
            wins.
          </p>
        </li>
      </ul>

      <h3>Tips</h3>
      <p>
        Warship is all about calling the right shots at the right time! Try to
        be as unpredictable as possible in your fleet arrangement on the grid.
        Avoid placing ships touching each other. Try placing them asymmetrically
        and on the edge of the board. Observe your opponents play across the
        rounds - if you have followed the above rules for a few rounds against
        the same opponent, break the pattern to confuse them.
      </p>
      <p>
        For guessing enemy locations, try firing shots along a diagonal line.
        Shoot in a distributed manner - don’t bunch up your shots early on. When
        you hit a new ship, fire adjacent spaces to sink the ship.
      </p>

      <h3>Classic Battleships in a Multiplayer War</h3>
      <p>
        Battleship used to be played between 2 players only, in long duration
        rounds. Mega Warship is an innovative way to play Battleship online -
        play against multiple players in fun, short rounds on any game table.
        Mega allows only 100% verified profiles to compete on the app. No bots,
        no tricks, no trolls. Pure skills, pure action!
      </p>

      <h3>Quick Battles, Fun graphics</h3>
      <p>
        Raging fires and fast missiles! The battle has been brought directly to
        your mobile screens. Warship is designed for fast paced gameplay,
        featuring exciting action and animations. The clean interface and
        thoughtful design helps you make the right choices without any
        distractions. So you can have a truly skill-based battle of the minds.
      </p>

      <h3>Exciting Wins, Instant withdrawals.</h3>
      <p>
        Win real money sinking enemy ships! Each battle in Warship ends with
        real cash wins. You can play any number of games on the table, and the
        total amount won is transferred to your Winnings wallet as soon as you
        leave the table.
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="Warship"
      />
    </div>
  )
}

export default WarshipInfo
